import type { ButtonHTMLAttributes } from "react";
import { ImSpinner8 } from "react-icons/im";
import { twMerge } from "tailwind-merge";

export const Button = ({
  children,
  className,
  loading,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & { loading?: boolean }) => {
  return (
    <button
      type="button"
      className={twMerge(
        "rounded-md bg-sky-500 px-3 py-2.5 text-sm font-medium text-white inline-flex items-center gap-2 disabled:opacity-55 disabled:saturate-0",
        className
      )}
      {...props}
    >
      {children}
      {loading && <ImSpinner8 className="animate-spin-loading" />}
    </button>
  );
};
